.downloadBadges {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  grid-gap: 20px;

  .icon {
    width: 215px;
  }

  .iconAndroid {
    width: 190px;
  }
}

.appDescription {
  padding-bottom: 30px;
}

.appImageContainer {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  img {
    object-fit: contain;
    height: 150px;
    border-radius: 20px;
  }
}